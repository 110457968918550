<script setup lang="ts">
import Modal from "./Modal.vue";

interface Props {
  show: boolean;
  maxWidth?: string;
  closeable?: boolean;
  overflow?: string;
  footerJustify?: string;
}

interface Emits {
  (e: "close"): void;
}

withDefaults(defineProps<Props>(), {
  maxWidth: "max-w-xl",
  overflow: 'overflow-hidden',
  footerJustify: 'justify-end',
  closeable: true,
});

const emit = defineEmits<Emits>();

const close = () => {
  emit("close");
};
</script>

<template>
  <Modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close" :overflow="overflow">
    <div class="px-6 py-4">
      <div class="font-medium text-black-gray text-lg">
        <slot name="title" />
      </div>

      <div class="mt-4 text-dark-gray text-sm">
        <slot name="content" />
      </div>
    </div>

    <div v-if="$slots.footer" class="bg-gray-100 flex  flex-row p-2 text-right" :class="footerJustify">
      <slot name="footer" />
    </div>
  </Modal>
</template>
